<template>
    <div class="account-flow-box sm-qu-padding">
         <CRow>
      <CCol sm="12">
        <CTableWrapper
          :items="data1"
          hover
          striped
          border
          small
          fixed
          dark
          @searchData="initData"
          :pages="pages"
          caption="Combined All dark Table"
        />
      </CCol>
    </CRow>
    </div>
</template>
<script>
import CTableWrapper from '../../components/AccountFlowTable.vue'
import { getTransctionDetail } from "../../api/http"
export default {
    data() {
        return {
            data1: [],
            pages: null,
            data: [
                {
                    CreateTime: '2021/11/01 12:00:23',
                    Type: "Deposit",
                    Amount: '10,000',
                    Status: "Pending",
                    Card: {
                        name: 'Maurice Sevilla',
                        code: '362837273878883633'
                    },
                    Remark: {
                        info: 'Deposit remark',
                        code: ''
                    },
                    Description: 'Description'
                },
                {
                    CreateTime: '2021/11/01 12:00:23',
                    Type: "Recharge",
                    Amount: '-10,000',
                    Status: "Complete",
                    Card: {
                        name: 'Maurice Sevilla',
                        code: '1231231231231231231'
                    },
                    Remark: {
                        info: '-',
                        code: ''
                    },
                    Description: 'Description'
                },
                {
                    CreateTime: '2021/11/01 12:00:23',
                    Type: "Withdraw",
                    Amount: '-10,000',
                    Status: "Frozen",
                    Card: {
                        name: 'Maurice Sevilla',
                        code: '111136792649499696'
                    },
                    Remark: {
                        info: 'Withdraw Remark',
                        code: ''
                    },
                    Description: 'Description'
                },
                {
                    CreateTime: '2021/11/01 12:00:23',
                    Type: "Refund",
                    Amount: '10,000',
                    Status: "Complete",
                    Card: {
                        name: 'Maurice Sevilla',
                        code: '1231231231231231231'
                    },
                    Remark: {
                        info: '-',
                        code: ''
                    },
                    Description: 'Description'
                },
                {
                    CreateTime: '2021/11/01 12:00:23',
                    Type: "Fee",
                    Amount: '-10',
                    Status: "Complete",
                    Card: {
                        name: 'Maurice Sevilla',
                        code: '1231231231231231231'
                    },
                    Remark: {
                        info: 'International transaction',
                        code: '839438'
                    },
                    Description: 'Description'
                },
                {
                    CreateTime: '2021/11/01 12:00:23',
                    Type: "Fee",
                    Amount: '-1',
                    Status: "Complete",
                    Card: {
                        name: 'Maurice Sevilla',
                        code: '1231231231231231231'
                    },
                    Remark: {
                        info: 'Spend under $10',
                        code: '823839'
                    },
                    Description: 'Description'
                },
                {
                    CreateTime: '2021/11/01 12:00:23',
                    Type: "Fee",
                    Amount: '-1',
                    Status: "Complete",
                    Card: {
                        name: 'Maurice Sevilla',
                        code: '1231231231231231231'
                    },
                    Remark: {
                        info: 'Spend under $10',
                        code: '823839'
                    },
                    Description: 'Description'
                },
                {
                    CreateTime: '2021/11/01 12:00:23',
                    Type: "Fee",
                    Amount: '-1',
                    Status: "Complete",
                    Card: {
                        name: 'Maurice Sevilla',
                        code: '1231231231231231231'
                    },
                    Remark: {
                        info: 'Spend under $10',
                        code: '823839'
                    },
                    Description: 'Description'
                },
                {
                    CreateTime: '2021/11/01 12:00:23',
                    Type: "Fee",
                    Amount: '-1',
                    Status: "Complete",
                    Card: {
                        name: 'Maurice Sevilla',
                        code: '1231231231231231231'
                    },
                    Remark: {
                        info: 'Spend under $10',
                        code: '823839'
                    },
                    Description: 'Description'
                },
                {
                    CreateTime: '2021/11/01 12:00:23',
                    Type: "Fee",
                    Amount: '-1',
                    Status: "Complete",
                    Card: {
                        name: 'Maurice Sevilla',
                        code: '1231231231231231231'
                    },
                    Remark: {
                        info: 'Spend under $10',
                        code: '823839'
                    },
                    Description: 'Description'
                },
                {
                    CreateTime: '2021/11/01 12:00:23',
                    Type: "Fee",
                    Amount: '-1',
                    Status: "Complete",
                    Card: {
                        name: 'Maurice Sevilla',
                        code: '1231231231231231231'
                    },
                    Remark: {
                        info: 'Spend under $10',
                        code: '823839'
                    },
                    Description: 'Description'
                }
            ]
        }
    },
    methods: {
        initData(before,after,card_number,atype,page) {
            let data = {
                type: "get_account_transaction",
                uid: window.localStorage.getItem("uid"),
                before: before || "",
                after: after || "",
                card_number: card_number || "",
                atype: atype || "",
                page: page ||  1,
                page_size: 20
            }
            getTransctionDetail(data).then((res) => {
                // console.log('detail res==>', res)
                this.pages = Number(res.data.size)
                this.data1 = res.data.data
            })
        }
    },
    created() {
        this.initData()
    },
    components: {
        CTableWrapper
    }
}
</script>
<style scoped>
.account-flow-box{
    padding-left: 2.6rem;
    overflow: hidden;
}
@media screen and (max-width: 520px){
    .sm-qu-padding{
      padding: 0;
    }
  }
</style>